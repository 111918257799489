import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../components/Layout';
import Select from '../../../components/Select';
import Table from '../../../components/Table';

import useSnackbarText from '../../../hooks/useSnackbarText';
import useQueryParams from '../../../hooks/useQueryParams';

import group from '../../../api/group';
import contests from '../../../api/contests';
import {
  getObjectFromArray,
  getYearList,
  getSitemapData,
  getFooterTotal,
} from '../../../utils';
import { Grid, Typography } from '@material-ui/core';

const sitemapValue = 'GROUP_GROUP_CONTEST';
const { label } = getSitemapData(sitemapValue);

/** Start from `1` */
function getMonthHeaders(month) {
  const months = moment.monthsShort();
  const monthIndex = monthOptions.findIndex(e => e.value === month);
  const key = months.map(e => e.toUpperCase())[monthIndex];

  const monthHeaders = [
    {
      id: 'name',
      label: 'Name',
      type: 'string',
      sticky: true,
      disableSort: true,
    },
  ];

  monthHeaders.push({
    id: key,
    label: months[monthIndex],
    type: 'string',
    subheaders: [
      { id: `${key}_ANP`, label: 'ANP', type: 'float' },
      { id: `${key}_NOC`, label: 'NOC', type: 'int' },
    ],
  });

  return monthHeaders;
}

function getQuarterHeaders(quarter) {
  const quarterHeaders = [
    {
      id: 'name',
      label: 'Name',
      type: 'string',
      sticky: true,
      disableSort: true,
    },
  ];

  quarterHeaders.push({
    id: `Q${quarter}`,
    label: `Quarter ${quarter}`,
    type: 'string',
    subheaders: [
      { id: `Q${quarter}_ANP`, label: 'ANP', type: 'float' },
      { id: `Q${quarter}_NOC`, label: 'NOC', type: 'int' },
    ],
  });

  return quarterHeaders;
}

const periodOptions = [
  { label: 'Month', value: 'MONTH' },
  { label: 'Quarter', value: 'QUARTER' },
];

const monthOptions = moment.monthsShort().map(e => ({
  label: e,
  value: e.toUpperCase(),
}));

const quarterOptions = [
  { label: 'Quarter 1', value: '1' },
  { label: 'Quarter 2', value: '2' },
  { label: 'Quarter 3', value: '3' },
  { label: 'Quarter 4', value: '4' },
];

const useStyles = makeStyles(theme => ({ select: { marginRight: 10 } }));

function GroupContest() {
  const classes = useStyles();
  const handleSnackbarChange = useSnackbarText();

  // number[][]
  const [groups, setGroups] = useState([[], [], [], []]);
  const [period, setPeriod] = useState({
    category: 'QUARTER', // QUARTER | MONTH
    year: moment().year(),
    quarter: moment().quarter(),
    month: monthOptions[moment().month()].value,
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleChange = type => event => {
    const value =
      type === 'category' || type === 'month'
        ? event.target.value
        : Number(event.target.value);
    console.log(`value22`, value);

    setPeriod(prev => ({ ...prev, [type]: value }));
  };

  useEffect(() => {
    const getData = async () => {
      setData([]);
      setLoading(true);
      let { data, success } = await group.getMonthlySubmission({
        year: period.year,
      });
      setLoading(false);

      if (success) {
        setData(data.submissions);
      } else {
        handleSnackbarChange({ text: data.message, variant: 'error' });
      }
    };

    if (period.year) getData();
  }, [period.year, handleSnackbarChange]);

  let _quarter = Number(period.quarter);
  if (period.category === 'MONTH') {
    const monthIndex = monthOptions.findIndex(e => e.value === period.month);
    _quarter = Math.floor(monthIndex / 3) + 1;
  }

  useEffect(() => {
    async function main() {
      setGroups([[], [], [], []]);
      const result = await contests.getContestGroups({
        year: period.year,
        quarter: _quarter,
      });

      if (result.success) {
        const res = result.data;
        const group1 = res.groups.filter(group => group.groupID === 1);
        const group2 = res.groups.filter(group => group.groupID === 2);
        const group3 = res.groups.filter(group => group.groupID === 3);
        const group4 = res.groups.filter(group => group.groupID === 4);

        const groups = [
          group1.map(group => group.agentID),
          group2.map(group => group.agentID),
          group3.map(group => group.agentID),
          group4.map(group => group.agentID),
        ];
        setGroups(groups);
      }
    }

    main();
  }, [period.year, _quarter]);

  function getFormattedData(groupID) {
    const agentIDs = groups[groupID - 1];

    const _data = data.filter(d => {
      return agentIDs.includes(d.agentID);
    });

    const formattedData = _data.map(e => {
      let tmp = { agentID: e.agentID, name: e.name };
      let months = moment.monthsShort().map(el => el.toUpperCase());

      // set monthly submissions
      e.submissions.forEach((el, j) => {
        tmp[`${months[j]}_ANP`] = el.ANP;
        tmp[`${months[j]}_NOC`] = el.NOC;
      });

      // set quarterly submissions
      tmp.Q1_ANP = e.submissions.slice(0, 3).reduce((a, v) => a + v.ANP, 0);
      tmp.Q1_NOC = e.submissions.slice(0, 3).reduce((a, v) => a + v.NOC, 0);
      tmp.Q2_ANP = e.submissions.slice(3, 6).reduce((a, v) => a + v.ANP, 0);
      tmp.Q2_NOC = e.submissions.slice(3, 6).reduce((a, v) => a + v.NOC, 0);
      tmp.Q3_ANP = e.submissions.slice(6, 9).reduce((a, v) => a + v.ANP, 0);
      tmp.Q3_NOC = e.submissions.slice(6, 9).reduce((a, v) => a + v.NOC, 0);
      tmp.Q4_ANP = e.submissions.slice(9, 12).reduce((a, v) => a + v.ANP, 0);
      tmp.Q4_NOC = e.submissions.slice(9, 12).reduce((a, v) => a + v.NOC, 0);

      // set half yearly submissions
      tmp.HY1_ANP = e.submissions.slice(0, 6).reduce((a, v) => a + v.ANP, 0);
      tmp.HY1_NOC = e.submissions.slice(0, 6).reduce((a, v) => a + v.NOC, 0);
      tmp.HY2_ANP = e.submissions.slice(6, 12).reduce((a, v) => a + v.ANP, 0);
      tmp.HY2_NOC = e.submissions.slice(6, 12).reduce((a, v) => a + v.NOC, 0);

      // set whole year submissions
      tmp.WY_ANP = e.submissions.reduce((a, v) => a + v.ANP, 0);
      tmp.WY_NOC = e.submissions.reduce((a, v) => a + v.NOC, 0);

      return tmp;
    });

    const footers = [
      {
        name: 'Total',
        ...getFooterTotal(
          period.category === 'MONTH'
            ? getMonthHeaders(period.month)
            : getQuarterHeaders(period.quarter),
          formattedData
        ),
      },
    ];

    return { formattedData, footers };
  }

  return (
    <Layout title={label}>
      <Select
        className={classes.select}
        maxWidth={150}
        label="Year"
        options={getYearList()}
        value={String(period.year)}
        onChange={handleChange('year')}
      />

      <Select
        className={classes.select}
        maxWidth={150}
        label="Period"
        options={periodOptions}
        value={period.category}
        onChange={handleChange('category')}
      />

      {period.category === 'MONTH' && (
        <Select
          className={classes.select}
          maxWidth={150}
          label="Month"
          options={monthOptions}
          value={String(period.month)}
          onChange={handleChange('month')}
        />
      )}

      {period.category === 'QUARTER' && (
        <Select
          className={classes.select}
          maxWidth={150}
          label="Quarter"
          options={quarterOptions}
          value={String(period.quarter)}
          onChange={handleChange('quarter')}
        />
      )}

      <Grid container>
        {groups.map((group, index) => {
          const { formattedData, footers } = getFormattedData(index + 1);

          let headers = [];
          if (period.category === 'MONTH') {
            headers = getMonthHeaders(period.month);
          }

          if (period.category === 'QUARTER') {
            headers = getQuarterHeaders(period.quarter);
          }

          return (
            <Grid key={index} item xs={12} lg={6}>
              {formattedData.length > 0 ? (
                <Table
                  title={`Group ${index + 1}`}
                  headers={headers}
                  data={formattedData}
                  footers={footers}
                  isLoading={loading}
                />
              ) : (
                <Typography style={{ color: '#acacac' }}>
                  {`Group ${index + 1} members not defined yet`}
                </Typography>
              )}
            </Grid>
          );
        })}
      </Grid>
    </Layout>
  );
}

export default GroupContest;
